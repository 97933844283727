<template>
  <div class="pt-1">

    <hb-data-table-header left-columns="6" right-columns="6">
      <template v-slot:actions>
            <hb-btn small v-if="hasPermission('schedule_exports')" class="pr-3" color="secondary" @click="showExport(true)">Schedule an Export</hb-btn>
            <hb-btn small v-if="hasPermission('generate_exports')" color="primary" @click="showExport(false)">Export Now</hb-btn>
      </template>
    </hb-data-table-header>
    <hb-data-table :headers="headers" :items="scheduleExportItems" :items-per-page="1000" @click:row="editExport">

      <template v-slot:header.actions="{ header }">
        <div class="d-flex justify-center content-center">{{ header.text }}</div>
      </template>

      <template v-slot:item.frequency="{ item }">
          <span class="text-capitalize" v-if="item.frequency == 'biweekly'">Every Two Weeks</span>
          <span class="text-capitalize" v-else>{{item.frequency}}</span>
      </template>
      <template v-slot:item.properties="{ item }">
        <hb-tooltip>
          <template v-slot:item>
            <span class="border-bottom-dashed">
              {{item.properties.length}} Properties
            </span>
          </template>
          <template v-slot:body>
            <span v-if="item.properties.length > 0">
              <v-row class="ma-0 pa-0" v-for="(property, i) in item.properties" :key="i">
                  <span>
                      {{ facilityList.find(i => i.id == property.id)?.name }}
                  </span>
              </v-row>
            </span>
            <span v-else>
                No Properties
            </span>
          </template>
        </hb-tooltip>
      </template>
      <template v-slot:item.type="{ item }">
          <span class="text-capitalize">{{item.type}}</span>
      </template>
      <template v-slot:item.details="{ item }">
          <span v-if="item.frequency == 'daily'">-</span>
          <span v-else-if="item.day_of_week" class="text-capitalize">{{item.day_of_week}}</span>
          <span v-else-if="item.day_of_month" class="text-capitalize">{{item.day_of_month | addOrdinal}}</span>
      </template>
      <template v-slot:item.format="{ item }">
        <span v-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF_CLASS_CODE.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF_CLASS_CODE.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.GENERAL_LEDGER.FORMATS.PDF.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.GENERAL_LEDGER.FORMATS.PDF.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.CSV.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.YARDI.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.YARDI.FORMATS.CSV.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL.FORMATS.CSV.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.LABEL}}</span>

        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.SAGE_INTACCT.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.SAGE_INTACCT.FORMATS.CSV.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.ACCOUNTANT_SUMMARY.FORMATS.PDF.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.ACCOUNTANT_SUMMARY.FORMATS.PDF.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.BALANCE_SHEET.FORMATS.PDF.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.BALANCE_SHEET.FORMATS.PDF.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTION_DETAILS.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTION_DETAILS.FORMATS.CSV.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTIONAL_JOURNAL.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTIONAL_JOURNAL.FORMATS.CSV.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTIONAL_JOURNAL.FORMATS.PDF.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTIONAL_JOURNAL.FORMATS.PDF.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.APPFOLIO.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.APPFOLIO.FORMATS.CSV.LABEL}}</span>
        <span v-else-if="item.format == ACCOUNTING.ACCOUNTING_EXPORTS.GREAT_PLAINS_EXPORTS.FORMATS.CSV_ZIP.KEY"> {{ACCOUNTING.ACCOUNTING_EXPORTS.GREAT_PLAINS_EXPORTS.FORMATS.CSV_ZIP.LABEL}} </span>

        <span class="text-uppercase" v-else>.{{item.format}}</span>
      </template>
      <template v-slot:item.send_to="{ item }">
        <hb-tooltip>
          <template v-slot:item>
            <span class="border-bottom-dashed">
              {{typeof item?.send_to === 'string' ? JSON.parse(item?.send_to).length : item?.send_to.length}} Recipients
            </span>
          </template>
          <template v-slot:body>
            <span v-if="typeof item?.send_to === 'string' ? JSON.parse(item?.send_to).length : item?.send_to.length > 0">
              <v-row class="ma-0 pa-0" v-for="(recipient, i) in (typeof item?.send_to === 'string' ? JSON.parse(item?.send_to) : item?.send_to)" :key="i">
                  <span>
                      <span v-if="recipient?.name">{{recipient?.name}}</span>
                      <span v-else>{{recipient?.email}}</span>
                  </span>
              </v-row>
            </span>
            <span v-else>
                No Recipients
            </span>
          </template>
        </hb-tooltip>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end content-center">

          <hb-menu options>

            <v-list>

                <v-list-item @click="editExport(item)">
                  <v-list-item-title>
                    View / Edit
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="openDeleteExportModal(item)">
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
              </v-list-item>

            </v-list>

          </hb-menu>

        </div>
      </template>
    </hb-data-table>
    <hb-modal v-model="showExportNow" v-if="showExportNow" :title="exportTitle" show-help-link @close="closeExportModal">
      <template v-slot:subheader>
        {{ exportSubHeader }}
      </template>
      <template v-slot:content>
        <div>
          <hb-form label="Export Range" description="Select the date range you want to include in this export" required :multiple="formData.export_range == 'date_range' || formData.export_range == 'date'" v-if="!scheduleExport">
            <v-row class="ma-0">
              <v-col :md="formData.export_range == 'date_range' || formData.export_range == 'date' ? 4 : 12" class="pa-0" style="padding-top: 6px !important">
                <v-select
                  v-model="formData.export_range"
                  :items="ACCOUNTING.EXPORT_RANGE"
                  item-text="text"
                  item-value="value"
                  v-validate="'required'"
                  id="export_range"
                  name="export_range"
                  :error="errors.collect('export_range').length > 0"
                  single-line
                  hide-details
                ></v-select>
              </v-col>
              <v-col md="4" class="pa-0 px-4" v-if="formData.export_range == 'date_range' || formData.export_range == 'date'">
                <hb-date-picker
                  :max="yesterday"
                  label="Start Date"
                  :solo="false"
                  single-line
                  hide-details
                  id="start_date"
                  name="start_date"
                  :error="errors.collect('start_date').length > 0"
                  v-validate="'required'"
                  data-vv-as="start_date"
                  v-model="formData.start_date"
                  :removePaddingMargin="true"
                ></hb-date-picker>
              </v-col>
              <v-col md="4" class="pa-0" v-if="formData.export_range == 'date_range'">
                <hb-date-picker
                  :max="yesterday"
                  :min="formData.start_date"
                  label="End Date"
                  :solo="false"
                  single-line
                  hide-details
                  id="end_date"
                  name="end_date"
                  :error="errors.collect('end_date').length > 0"
                  v-validate="'required'"
                  data-vv-as="end_date"
                  v-model="formData.end_date"
                  :removePaddingMargin="true"
                ></hb-date-picker>
              </v-col>
            </v-row>
          </hb-form>
          <hb-form label="Schedule Details" description="Select how often this export will be generated" required v-if="scheduleExport">
            <v-row class="ma-0">
              <v-col class="pa-0">
                <v-select
                  v-model="formData.frequency"
                  :items="ACCOUNTING.FREQUENCY_LIST"
                  item-text="text"
                  item-value="value"
                  v-validate="'required'"
                  id="export_range"
                  name="export_range"
                  :error="errors.collect('export_range').length > 0"
                  single-line
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="ma-0" v-if="formData.frequency != 'daily'">
              <span class="py-4 hb-text-night-light">{{fieldSubTitle}}</span>
            </v-row>
            <v-row class="ma-0" v-if="formData.frequency != 'daily'">
              <v-col class="pa-0" v-if="formData.frequency == 'weekly' || formData.frequency == 'biweekly'">
                <v-select
                  v-model="formData.day_of_week"
                  :items="ACCOUNTING.WEEk_DAYS"
                  v-validate="'required'"
                  label="Select the day of the week"
                  id="day_of_week"
                  name="day_of_week"
                  data-vv-as="day of week"
                  :error="errors.collect('day_of_week').length > 0"
                  single-line
                  hide-details
                >
                  <template v-slot:selection="{item}">
                    <span class="text-capitalize">{{item}}</span>
                  </template>
                  <template v-slot:item="{item}">
                    <span class="text-capitalize">{{item}}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col class="pa-0"  v-if="formData.frequency == 'monthly' || formData.frequency == 'quarterly'">
                <hb-date-picker
                  :solo="false"
                  single-line
                  hide-details
                  id="date"
                  name="date"
                  label="Select the Date"
                  :error="errors.collect('date').length > 0"
                  v-validate="'required'"
                  data-vv-as="date"
                  v-model="formData.date"
                  :removePaddingMargin="true"
                ></hb-date-picker>
              </v-col>
            </v-row>
          </hb-form>
          <hb-form v-if="formData.format !== ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.KEY" label="Book Type" required>
            <v-select
              :items="bookTypes"
              v-model="formData.book_id"
              item-text="text"
              item-value="value"
              item-disabled="pdf_accountant_summary"
              v-validate="'required'"
              id="book_type"
              name="book_type"
              :error="errors.collect('book_type').length > 0"
              label="Select Book Type"
              data-vv-as="book type"
              single-line
              hide-details
            ></v-select>
          </hb-form>
          <hb-form v-if="formData.book_id !== accountingTypes.gaap_accrual?.id" label="Format of Export" description="Select the file format for this export" required>
            <v-select
              v-model="formData.format"
              :items="ACCOUNTING.FORMAT_LIST"
              item-text="text"
              item-value="value"
              v-validate="'required'"
              id="type"
              name="type"
              :error="errors.collect('type').length > 0"
              single-line
              hide-details
              class="text-capitalize"
            ></v-select>
            <div class="mt-2">
              <span class="hb-font-caption">{{ exportFormatDescription }}</span>
            </div>
          </hb-form>
          <hb-form label="Type of Export" description="Select the export type" required>
            <v-select
              v-model="formData.type"
              :items="ACCOUNTING.TYPE_LIST"
              item-text="text"
              item-value="value"
              v-validate="'required'"
              :disabled="disableType"
              id="type"
              name="type"
              :error="errors.collect('type').length > 0"
              single-line
              hide-details
              class="text-capitalize"
            ></v-select>
          </hb-form>
          <hb-form v-if="formData.format !== ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.KEY" label="Properties" description="Select the properties you want to include in this export" required>
            <hb-combobox
              v-model="formData.properties"
              :items="filteredExportProperties"
              item-text="name"
              label="Select Properties"
            ></hb-combobox>
          </hb-form>
          <hb-form label="Send To" description="Select the recipients you want to send this export to" last>
            <hb-combobox
              v-model="formData.send_to"
              :items="adminsList"
              item-text="first"
              return-object
              label="Add administrators and additional recipients"
            ></hb-combobox>
          </hb-form>
        </div>
      </template>
      <template v-slot:actions>
          <hb-btn color="primary" @click="submitForm" :disabled="formData.format !== ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.KEY && !formData.properties.length">
            <template v-if="scheduleExport">Schedule</template><template v-else>Export</template>
          </hb-btn>
      </template>
    </hb-modal>
    <hb-modal v-model="confirmRemoveExport" v-if="confirmRemoveExport" size="medium" title="Delete Scheduled Export" confirmation show-help-link @close="closeDeleteExportModal()">
      <template v-slot:content>
          <div class="pa-5">
              You’re about to delete the scheduled export for:
              <br /><br />
              <span class="text-capitalize">
                <span v-if="selectedExport.frequency == 'biweekly'">Every Two Weeks</span>
                <span v-else>{{selectedExport.frequency}}</span>
                {{selectedExport.type}}

                <span v-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF_CLASS_CODE.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF_CLASS_CODE.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.GENERAL_LEDGER.FORMATS.PDF.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.GENERAL_LEDGER.FORMATS.PDF.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.CSV.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.YARDI.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.YARDI.FORMATS.CSV.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL.FORMATS.CSV.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.LABEL}}</span>

                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.SAGE_INTACCT.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.SAGE_INTACCT.FORMATS.CSV.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.ACCOUNTANT_SUMMARY.FORMATS.PDF.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.ACCOUNTANT_SUMMARY.FORMATS.PDF.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.BALANCE_SHEET.FORMATS.PDF.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.BALANCE_SHEET.FORMATS.PDF.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTION_DETAILS.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTION_DETAILS.FORMATS.CSV.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTIONAL_JOURNAL.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTIONAL_JOURNAL.FORMATS.CSV.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTIONAL_JOURNAL.FORMATS.PDF.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTIONAL_JOURNAL.FORMATS.PDF.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.APPFOLIO.FORMATS.CSV.KEY">{{ACCOUNTING.ACCOUNTING_EXPORTS.APPFOLIO.FORMATS.CSV.LABEL}}</span>
                <span v-else-if="selectedExport.format == ACCOUNTING.ACCOUNTING_EXPORTS.GREAT_PLAINS_EXPORTS.FORMATS.CSV_ZIP.KEY"> {{ACCOUNTING.ACCOUNTING_EXPORTS.GREAT_PLAINS_EXPORTS.FORMATS.CSV_ZIP.LABEL}} </span>

                <span class="text-uppercase" v-else>.{{selectedExport.format}}</span>
                @
                <span v-if="selectedExport.day_of_week" class="text-capitalize">{{selectedExport.day_of_week}}</span>
                <span v-else-if="selectedExport.day_of_month" class="text-capitalize">{{selectedExport.day_of_month | addOrdinal}}</span>
                <span v-if="selectedExport.frequency == 'daily'">12:00AM</span>
                <span class="text-lowercase"> for </span>
                <span v-for="(item, index) in selectedExport.properties">{{item?.name}}<span v-if="index < selectedExport.properties.length - 1">, </span><span v-else>.</span></span>
              </span>
          </div>
      </template>
      <template v-slot:actions>
          <hb-btn color="destructive" @click="deleteExport(selectedExport.id)">Delete</hb-btn>
      </template>
    </hb-modal>

    <edit-export
      v-model="showEditExport"
      v-if="showEditExport"
      :formData.sync="formData"
      :adminsList.sync="adminsList"
      @close="closeExportModal"
      @save="submitForm"
    ></edit-export>


  </div>
</template>

<script>
import { ACCOUNTING } from "@/constants/accounting.js";
import api from '../../assets/api.js';
import HbDatePicker from '../assets/HummingbirdDatepicker';
import EditExport from './EditExport.vue';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { notificationMixin } from  '../../mixins/notificationMixin.js';

export default {
  name: "Exports",
  mixins: [ notificationMixin ],
  components : {
    HbDatePicker,
    EditExport
  },
  data() {
    return {
      showEditExport: false,
      active: '',
      showSuccess: false,
      showExportNow: false,
      scheduleExport: false,
      formData: {
        date: null,
        day_of_week: 'Monday',
        day_of_month: null,
        frequency: 'daily',
        start_date: '',
        end_date: '',
        export_range: 'yesterday',
        format: 'pdf',
        type: 'summarized',
        book_id: '',
        properties: [],
        send_to: [],
        editing: false
      },
      adminsList: [],
      disableType: true,
      accountingType: {},
      headers: [
        {
          text: "Type",
          sortable: false,
          value: "frequency",
          class: "table-font-13"
        },
        {
          text: "Details",
          sortable: false,
          value: "details",
          class: "table-font-13"
        },
        {
          text: "Properties",
          sortable: false,
          value: "properties",
          class: "table-font-13"
        },
        {
          text: "Type of Export",
          sortable: false,
          value: "type",
          class: "table-font-13"
        },
        {
          text: "Format of Export",
          sortable: false,
          value: "format",
          class: "table-font-13"
        },
        {
          text: "Scheduled By",
          sortable: false,
          value: "scheduled_by",
          class: "table-font-13"
        },
        {
          text: "Sent To",
          sortable: false,
          value: "send_to",
          class: "table-font-13"
        },
        {
          text: "",
          sortable: false,
          value: "actions",
          class: "table-font-13"
        }
      ],
      confirmRemoveExport: false,
      selectedExport: '',
      exportProperties: [],
      bookTypes: null
    };
  },
  async created () {
    this.ACCOUNTING = ACCOUNTING;
    this.resetFormData();
    await this.fetchExportProperties();
    await this.fetchAdmins();
    await this.getExports();
    await this.getAccountingTypes();
    this.bookTypes = this.ACCOUNTING.BOOK_TYPES
    if (!this.accountingTypes.gaap_accrual){
      this.bookTypes = this.bookTypes.filter(book => book.value != 3);
    }
  },
  destroyed() {
    this.resetFormData();
  },
  filters:{
    addOrdinal(value){
      if(!value) return null;
      var s = ["th","st","nd","rd"];
      var v = value % 100;
      return value + (s[(v-20)%10]||s[v]||s[0]);
    },
  },
  computed: {
    ...mapGetters({
      facilityList: 'propertiesStore/filtered',
      scheduleExportItems : 'accountingStore/exports',
      hasPermission: 'authenticationStore/rolePermission',
      accountingTypes: "accountingStore/accountingTypes",
    }),
    yesterday(){
      return moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
    },
    exportTitle() {
      return this.scheduleExport ? 'Schedule an Export' : 'Export Now';
    },
    exportSubHeader() {
      return this.scheduleExport ? 'Fill out the fields below to schedule an export. Set a repeating export schedule, down to the hour, for one or all of your properties.' : 'Fill out the fields below to schedule an export immediately.';
    },
    fieldSubTitle() {
      switch(this.formData.frequency) {
        case 'weekly':
        case 'biweekly':
          return 'Select a day of the week on which this export will be generated';
        break;
        case 'monthly':
        case 'quarterly':
          return 'Select a specific day of the month on which this export will be generated';
        break;
      }
    },
    dayOfMonth() {
      if(this.formData.date) {
        let date = moment(this.formData.date, 'YYYY-MM-DD');
        return date.format('D');
      }
      return null;
    },
    exportFormatDescription(){
      let format = ACCOUNTING.FORMAT_LIST.find(x=>x.value === this.formData.format);
      if(format) {
        return format.text;
      } else {
        ''
      }
    },
    filteredExportProperties() {
      if(this.formData?.book_id != null) {
        this.formData.properties = [];
        if(this.formData?.book_id == 0) return this.exportProperties.filter( x => (x.book_id == this.formData.book_id || x.book_id == 2));
        if(this.formData?.book_id == 1) return this.exportProperties.filter( x => (x.book_id == this.formData.book_id || x.book_id == 2));
        return this.exportProperties.filter(x=>x.book_id == this.formData.book_id);
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      getExports : 'accountingStore/getExports',
      getAccountingTypes: "accountingStore/getAccountingTypes",
    }),
    async fetchExportProperties(){
      let resp = await api.get(this, api.ACCOUNTING + 'export-properties');
      let filteredExportProperties = [];
      resp.forEach(p => {
          let index = this.facilityList.findIndex(x=> x.id === p.id)
          if (index !== -1) {
              filteredExportProperties.push(p)
          }
      });
      this.exportProperties = filteredExportProperties;
    },
    async fetchExportProperties(){
      let resp = await api.get(this, api.ACCOUNTING + 'export-properties');
      this.exportProperties = resp.length ? resp : [];
    },
    closeExportModal() {
      this.resetFormData();
      this.showExportNow = false;
      this.showEditExport = false;
    },
    setNotification(notificationType, notificationText) {
      this.showMessageNotification({ type: notificationType, description: notificationText })
    },
    showExport(schedule) {
      this.resetFormData();
      this.scheduleExport = schedule;
      this.showExportNow = true;
    },
    async fetchAdmins() {
      await api.get(this, api.ADMIN).then(r => {
        this.adminsList = r.admins.map(admin => {
          return {
            email: admin?.email,
            first:  admin?.first,
            last: admin?.last,
            name: admin?.first + ' ' + admin?.last
          }
        });
      });
    },
    formatSendToList() {
      this.formData.send_to.map((st, index) => {
        if(!st.email) {
          this.formData.send_to[index] = { email: st }
        }
      })
    },
    formatPropertiesList() {
      return this.formData.properties.map(item => {
        return item.id;
      });
    },
    async editModeSendToList(list) {
      await list.map((l, i) => {
        if(!l.name) {
          list[i].name = l.email;
        }
      });
      return list;
    },
    editModePropertiesList(list){
      return list.map(item => {
        return {
          id: item.id,
          name: this.facilityList.find(i => i.id == item.id).name
        }
      });
    },
    async submitForm(data = {}) {
      if(data.editing) this.formData = data;
      let timeZone = moment.tz.guess();
      this.$errors = '';
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }
      await this.formatSendToList();
      let propertyList = await this.formatPropertiesList();
      try {
        if(this.scheduleExport) {
          let data = {
            date: (this.formData.frequency == 'monthly' || this.formData.frequency == 'quarterly') ? this.formData.date : null,
            day_of_month: (this.formData.frequency == 'monthly' || this.formData.frequency == 'quarterly') ? this.dayOfMonth : 1,
            property_id : this.formData.property_id,
            format: this.formData.format,
            type: this.formData.type,
            frequency: this.formData.frequency,
            day_of_week: (this.formData.frequency == 'weekly' || this.formData.frequency == 'biweekly') ? this.formData.day_of_week : null,
            book_id: this.formData.book_id == "" ? this.accountingTypes.cash.id : this.formData.book_id,
            property_ids: propertyList.filter(x => x != null ),
            send_to: this.formData.send_to
          }

          if(this.formData.id) {
            let response = await api.put(this, api.ACCOUNTING + 'exports/scheduled/' + this.formData.id, data);
            await this.getExports();
            this.setNotification('success', 'You’ve edited this scheduled export.');
          } else {
            let response = await api.post(this, api.ACCOUNTING + 'exports/scheduled', data);
            await this.getExports();
            this.resetFormData();
            this.setNotification('success', 'You’ve scheduled an export.');
          }
        } else {
          let data = {
            book_id: this.formData.book_id == ""? this.accountingTypes.cash.id : this.formData.book_id,
            property_id : this.formData.property_id,
            format: this.formData.format,
            type: this.formData.type,
            export_range: this.formData.export_range,
            start_date: this.formData.start_date,
            end_date: this.formData.end_date,
            property_ids: propertyList.filter(x => x != null ),
            send_to: this.formData.send_to,
            timeZone: timeZone
          }
          let response = await api.post(this, api.ACCOUNTING + 'exports/now', data);
          if(response.socket) {
            const payload = { 
              type: 'document_download', 
              title: 'Generating Export',
              persistent: true,
              loading: true,
              progress: true,
              description: 'We are generating your document, it should begin downloading shortly.'
            }
            this.showMessageNotification(payload);
          }
        }
      }
      catch(error) {
        this.showMessageNotification({ description: error })
      }
    },
    async editExport(item){
      let sendTo = await this.editModeSendToList(typeof item.send_to === 'string' ? JSON.parse(item.send_to) : item.send_to);
      let propertiesList = await this.editModePropertiesList(item.properties);
      this.formData = {
        id: item.id,
        day_of_month: item.day_of_month,
        day_of_week : item.day_of_week,
        send_to: this.formData.send_to,
        date: item.date == '0000-00-00' ? null : item.date,
        frequency: item.frequency,
        format: item.format,
        type: item.type,
        book_id: item.book_id,
        properties : propertiesList,
        send_to: sendTo
      }
      this.showEditExport = true;
      this.scheduleExport = true;
    },
    async deleteExport(id) {
      try {
        await api.delete(this, api.ACCOUNTING + 'exports/scheduled/' + id);
        await this.getExports();
        this.closeDeleteExportModal();
        this.setNotification('success', 'You’ve deleted a scheduled export.');
      }
      catch(error) {
        this.showMessageNotification({ description: error })
      }
    },
    async openDeleteExportModal(i){
      let propertiesList = await this.editModePropertiesList(i.properties);
      this.selectedExport = i;
      this.selectedExport.properties = propertiesList;
      this.confirmRemoveExport = true;
    },
    closeDeleteExportModal(){
      this.selectedExport = '';
      this.confirmRemoveExport = false;
      this.resetFormData();
    },
    resetFormData() {
      this.showExportNow = false;
      this.formData = {
        date: null,
        day_of_week: 'Monday',
        day_of_month: null,
        frequency: 'daily',
        start_date: '',
        end_date: '',
        export_range: 'yesterday',
        format: 'pdf',
        type: 'summarized',
        book_id: '',
        properties: [],
        send_to: [],
        editing: false
      }
      this.formData.start_date = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
      this.formData.end_date = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');

    }
  },
  watch: {
    'formData.start_date': function () {
      if(this.formData.end_date < this.formData.start_date) {
        this.formData.end_date = this.yesterday;
      }
      if(this.formData.export_range == 'date') {
        this.formData.end_date = this.formData.start_date;
      }
    },
    'formData.export_range': function (range){
      switch(range) {
        case 'yesterday':
          this.formData.start_date = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
          this.formData.end_date = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
        break;
        case 'last_seven_days':
          this.formData.start_date = moment.utc().subtract(7, 'days').format('YYYY-MM-DD');
          this.formData.end_date = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');  //moment.utc().format('YYYY-MM-DD');
        break;
        case 'last_calendar_month':
          this.formData.start_date = moment.utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
          this.formData.end_date = moment.utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        break;
        case 'date_range':
          this.formData.start_date = this.yesterday;
          this.formData.end_date = this.yesterday;
        break;2
        case 'date':
          this.formData.start_date = this.yesterday;
          this.formData.end_date = this.yesterday;
        break;
      }
    },
    'formData.format': function(format){
      switch(format){
        case ACCOUNTING.ACCOUNTING_EXPORTS.GENERAL_LEDGER.FORMATS.PDF.KEY:
        case ACCOUNTING.ACCOUNTING_EXPORTS.ACCOUNTANT_SUMMARY.FORMATS.PDF.KEY:
        case ACCOUNTING.ACCOUNTING_EXPORTS.BALANCE_SHEET.FORMATS.PDF.KEY:
          this.disableType = true;
          this.formData.type = 'summarized';
          break;
        case ACCOUNTING.ACCOUNTING_EXPORTS.APPFOLIO.FORMATS.CSV.KEY:
          this.formData.type = 'summarized';
          break;
        case ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF.KEY:
        case ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF_CLASS_CODE.KEY:
        case ACCOUNTING.ACCOUNTING_EXPORTS.TRANSACTION_DETAILS.FORMATS.CSV.KEY:
          this.disableType = false;
          this.formData.type = 'detailed';
          break;
        case ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.KEY:
          this.disableType = true;
          this.formData.type = 'detailed';
          this.formData.properties = this.facilityList;
          break;
        default:
          this.disableType = false;
      }
    },
    'formData.book_id': function(book_id){
      if(this.accountingTypes.gaap_accrual && book_id === this.accountingTypes.gaap_accrual.id){
        this.formData.format = ACCOUNTING.ACCOUNTING_EXPORTS.GREAT_PLAINS_EXPORTS.FORMATS.CSV_ZIP.KEY;
      } else if(!ACCOUNTING.FORMAT_LIST.some(f => f.value === this.formData.format)){
        this.formData.format = ACCOUNTING.FORMAT_LIST[0].value;
      }
    }
  }
};
</script>

<style>
.border-bottom-dashed {
    border-bottom: 1px dashed;
}
</style>
