<template>
<div>
  <hb-modal
    v-model="dialog"
    title="Scheduled Export"
    :footerCancelOption="false"
    show-help-link
  >
    <template v-slot:subheader>Hover over and click on any field to edit this export.</template>
    <template v-slot:content>
      <hb-form label="Schedule Details" :description="active == 'Schedule Details' ? 'Select how often this export will be generated' : ''" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="!selected.frequency" @editStart="editHasStarted" required>
        <template v-slot:display>
            <span class="text-capitalize">{{ ACCOUNTING.FREQUENCY_LIST.find(f => f.value == selected.frequency).text }}</span>
        </template>
        <template v-slot:edit>
          <v-row class="ma-0">
            <v-col class="pa-0">
              <v-select
                v-model="selected.frequency"
                :items="ACCOUNTING.FREQUENCY_LIST"
                item-text="text"
                item-value="value"
                v-validate="'required'"
                id="export_range"
                name="export_range"
                :error="errors.collect('export_range').length > 0"
                single-line
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="ma-0" v-if="selected.frequency != 'daily'">
            <span class="py-4 ">{{fieldSubTitle}}</span>
          </v-row>
          <v-row class="ma-0" v-if="selected.frequency != 'daily'">
            <v-col class="pa-0" v-if="selected.frequency == 'weekly' || selected.frequency == 'biweekly'">
              <v-select
                v-model="selected.day_of_week"
                :items="ACCOUNTING.WEEk_DAYS"
                v-validate="'required'"
                placeholder="Select the day of the week"
                id="day_of_week"
                name="day_of_week"
                data-vv-as="day of week"
                :error="errors.collect('day_of_week').length > 0"
                single-line
                hide-details
              >
                <template v-slot:selection="{item}">
                  <span class="text-capitalize">{{item}}</span>
                </template>
                <template v-slot:item="{item}">
                  <span class="text-capitalize">{{item}}</span>
                </template>
              </v-select>
            </v-col>
            <v-col class="pa-0" v-if="selected.frequency == 'monthly' || selected.frequency == 'quarterly'">
              <hb-date-picker
                :solo="false"
                single-line
                hide-details
                id="date"
                name="date"
                placeholder="Select the Date"
                :error="errors.collect('date').length > 0"
                v-validate="'required'"
                data-vv-as="date"
                v-model="selected.date"
                :removePaddingMargin="true"
              ></hb-date-picker>
            </v-col>
          </v-row>
        </template>
      </hb-form>
      <hb-form v-if="selected.format !== ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.KEY" 
        label="Book Type" 
        @cancel="handleEmittedCancel" 
        @save="handleEmittedSave" 
        :active.sync="active" 
        editable 
        :empty="!isValidBookType" 
        @editStart="editHasStarted" required
      > 
        <template v-slot:display>
            <span class="text-capitalize">{{ ACCOUNTING.BOOK_TYPES.find(bt => bt.value == selected.book_id).text }}</span>
        </template>
        <template v-slot:edit>
          <v-row class="ma-0">
            <v-col class="pa-0">
              <v-select
                :items="ACCOUNTING.BOOK_TYPES"
                v-model="selected.book_id"
                item-text="text"
                item-value="value"
                v-validate="'required'"
                id="book_type"
                name="book_type"
                :error="errors.collect('book_type').length > 0"
                label="Select Book Type"
                data-vv-as="book type"
                single-line
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </template>
      </hb-form>
      <hb-form v-if="selected.book_id !== accountingTypes.gaap_accrual?.id" label="Format of Export" :description="active == 'Format of Export' ? 'Select the file format for this export' : ''" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="!selected.format" @editStart="editHasStarted" required>
        <template v-slot:display>
            <span class="text-capitalize">{{ ACCOUNTING.FORMAT_LIST.find(f => f.value == selected.format).text }}</span>
        </template>
        <template v-slot:edit>
          <v-row class="ma-0">
              <v-col class="pa-0 mb-n3">
                <hb-radio-group v-model="selected.format" v-for="(f, i) in ACCOUNTING.FORMAT_LIST" :key="i">
                  <hb-radio
                    :label="f.text"
                    :value="f.value"
                  ></hb-radio>
                </hb-radio-group>
              </v-col>
            </v-row>
        </template>
      </hb-form>
      <hb-form v-if="selected.format !== ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.KEY" label="Type of Export" :description="active == 'Type of Export' ? 'Select the export type' : ''" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="!selected.type" @editStart="editHasStarted" required>
        <template v-slot:display>
            <span class="text-capitalize">{{ selected.type }}</span>
        </template>
        <template v-slot:edit>
          <v-row class="ma-0">
            <v-col class="pa-0">
              <v-select
                v-model="selected.type"
                :items="ACCOUNTING.TYPE_LIST" 
                item-text="text"
                item-value="value"
                v-validate="'required'"
                :disabled="disableType"
                id="type"
                name="type"
                :error="errors.collect('type').length > 0"
                single-line
                hide-details
                class="text-capitalize"
              ></v-select>
            </v-col>
          </v-row>
        </template>
      </hb-form>
      <hb-form v-if="selected.format !== ACCOUNTING.ACCOUNTING_EXPORTS.YARDI_FINANCIAL_JOURNAL_IPP.FORMATS.CSV.KEY" label="Properties" :description="active == 'Properties' ? 'Select the property you want to include in this export' : ''" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable @editStart="editHasStarted" required>
        <template v-slot:display>
          <hb-chip v-for="(item, i) in selected.properties" :key="'combobox4' + i" display>
            {{ item.name }}
          </hb-chip>
        </template>
        <template v-slot:edit>
          <hb-combobox
            v-model="selected.properties"
            :items="facilityList"
            item-text="name"
            label="Select properties"
          ></hb-combobox>
        </template>
      </hb-form>
      <hb-form label="Send To" :description="active == 'Send To' ? 'Select the recipients you want to send this export to' : ''" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="!selected.send_to.length" @editStart="editHasStarted" last>
        <template v-slot:display>
          <hb-chip v-for="(item, i) in selected.send_to" :key="'combobox4' + i" display>
            {{ item.name }}
          </hb-chip>
        </template>
        <template v-slot:edit>
          <hb-combobox
            v-model="selected.send_to"
            :items="adminsList"
            item-text="first"
            return-object
            label="Add administrators and additional recipients"
          ></hb-combobox>
        </template>
      </hb-form>
    </template>
    <template v-slot:actions>
      <hb-btn
        color="secondary"
        @click="$emit('close')"
      >
        Close
      </hb-btn>
    </template>
  </hb-modal>
  <hb-global-notification
    v-model="notification"
    :type="type"
    :description="description"
    :list="errorsList"
  ></hb-global-notification>
</div>
</template>

<script>
import { ACCOUNTING } from "@/constants/accounting.js";
import HbDatePicker from '../assets/HummingbirdDatepicker';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "EditExport",
  components: {
    HbDatePicker
  },
	props: ["value", "formData", "adminsList"],
  data() {
    return {
      active: '',
      description: '',
      notification: false,
      type: '',
      errorsList: [],
      selected: {},
      disableType: false
    };
  },
  async created() {
    this.ACCOUNTING = ACCOUNTING;
    this.selected = Object.assign({}, this.formData);
    await this.getAccountingTypes();
  },
  destroyed() {},
  filters: {},
  computed: {
    ...mapGetters({
      facilityList: 'propertiesStore/filtered',
      accountingTypes: "accountingStore/accountingTypes",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    fieldSubTitle() {
      switch(this.selected.frequency) {
        case 'weekly':
        case 'biweekly':
          return 'Select a day of the week on which this export will be generated';
        break;
        case 'monthly':
        case 'quarterly':
          return 'Select a specific day of the month on which this export will be generated';
        break;
      }
    },
    isValidBookType(){
      return ACCOUNTING.BOOK_TYPES.some(bt => bt.value === this.selected.book_id);
    },
  },
  methods: {
    ...mapActions({
      getAccountingTypes: "accountingStore/getAccountingTypes",
    }),
    handleEmittedCancel(label){
      this.selected = Object.assign({}, this.formData);
    },
    async handleEmittedSave(label){
        const valid = await this.$validator.validateAll();
        if (!valid) {
            this.description = "There are errors in your form, correct them before continuing."
            this.type = 'error';
            this.notification = true;
            return;
        } else {
          this.selected.editing = true;
          this.$emit('save', this.selected);
          this.active = 'saved';
        }
    },
    editHasStarted(label){
      //any functionality required while editing goes here
    },
  },
  watch: {
    "errors.items"(val) {
      this.notification = val.length ? true : false;
      this.type = 'error';
      this.description = 'There are errors in your form, correct them before continuing.';
      this.errorsList = val;
    },
    'selected.format': function(format){
      switch(format){
        case ACCOUNTING.ACCOUNTING_EXPORTS.GENERAL_LEDGER.FORMATS.PDF.KEY:
          this.disableType = true;
          break;
        case ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF.KEY:
          this.disableType = true;
          break;
        case ACCOUNTING.ACCOUNTING_EXPORTS.QUICKBOOKS.FORMATS.IIF_CLASS_CODE.KEY:
          this.disableType = false;
          break;
        default:
          this.disableType = false;
      }
    },
    'selected.book_id': function(book_id){
      if(book_id === 3){
        this.selected.format = ACCOUNTING.ACCOUNTING_EXPORTS.GREAT_PLAINS_EXPORTS.FORMATS.CSV_ZIP.KEY;
      } else if(!ACCOUNTING.FORMAT_LIST.some(f => f.value === this.selected.format)){
        this.selected.format = ACCOUNTING.FORMAT_LIST[0].value;
      }
    }
  },
};
</script>

<style></style>
